<template>
  <div class="navbar-container bg p-0">
    <div class="navbar-container d-flex content align-items-center">
      <!-- Nav Menu Toggler -->

      <div>
        <i
          class="fas fa-bell fa-2x"
          style="color: #fff"
        />
      </div>

      <div class="m-auto">
        <b-img
          src="/rr.svg"
          height="30"
        />
      </div>

      <div>
        <i
          v-b-modal.modal-xl
          class="fas fa-bars fa-2x"
          style="color: #fff"
        />
      </div>

      <b-modal
        id="modal-xl"
        hide-footer
        centered
        size="xs"
        title="Extra Large Modal"
        hide-header
      >
        <div class="text-center">
          <router-link :to="{ name: 'auth-login' }">
            <b-button
              variant="gradient-warning"
              pill
              @click="logout"
            >
              <feather-icon
                icon="LogInIcon"
                class="mr-50"
              />
              <span class="align-middle">ออกจากระบบ</span>
            </b-button>
          </router-link>
        </div>
      </b-modal>
    </div>
    <div class="navbar-container bg2 p-0">
      <!-- Nav Menu Toggler -->
      <div class="container font-weight-bolder pd">
        <i class="fas fa-user-circle" />&nbsp; {{ UserData.username }}

        <!-- Right Col -->
        <b-navbar-nav class="nav align-items-center ml-auto">
          <!-- <user-dropdown /> -->

          <div>
            <!-- <i class="fas fa-sync" /> -->
            <b-badge class="font-weight-bolder">
              <i class="fas fa-coins" />&nbsp; {{ Commas(MainWallet) }}.00 ฿
            </b-badge>
            <!-- {{ UserData.username }} -->
          </div>
          <!-- <router-link :to="{ name: 'auth-login' }">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="gradient-warning"
            pill
          >
            <feather-icon
              icon="LogInIcon"
              class="mr-50"
            />
            <span class="align-middle">เข้าสูระบบ</span>
          </b-button>
          @click="logout"
        </router-link> -->
        </b-navbar-nav>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BNavbarNav, BImg, BModal, VBModal, BButton, BBadge,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
// import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    // Navbar Components
    BNavbarNav,
    // UserDropdown,
    BImg,
    BModal,
    BButton,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      Interval: null,
      MainWallet: 0,
      UserData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  mounted() {
    this.ShowWallet()
    this.Interval = setInterval(() => {
      this.ShowWallet()
    }, 2000)
  },
  methods: {
    async ShowWallet() {
      try {
        const { data: response } = await this.$http.get(
          'https://api.ma5lotto.com/api/wallet/show',
        )
        if (response && response.success === true) {
          const Main = response.WalletData.MainWallet
          this.MainWallet = Main.balance
          this.$store.state.appConfig.UserData.MainWallet = Main.balance
        } else {
          this.MainWallet = 0
          this.$store.state.appConfig.UserData.MainWallet = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    async logout() {
      await this.$http.post('https://api.ma5lotto.com/api/auth/logout')
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
.pd {
  padding-top: 3px;
  padding-bottom: 3px;
}
.bg2 {
  background-color: #868686;
  padding: 2px;
}
.bg {
  /* background: linear-gradient(268.87deg, #5e891f 0.54%, #c7dc2e 99.03%); */
  background-color: #414141;
  background-position: center bottom;
  border-radius: unset;
  background-size: cover;
  position: relative;
  z-index: unset;
}
i {
  color: #ffc400;
}
</style>
<style lang="scss" scoped>
::v-deep #full-screen-modal > .modal-dialog {
  max-width: 100%;
}
</style>
